define(['mediaContainer/factory/mediaContainerFactory', 'componentsCore'], function (mediaContainerFactory, componentsCore) {
    'use strict';

    const mediaContainer = mediaContainerFactory.createMediaContainer({displayName: 'MediaContainer', useBackgroundDetectionMixin: false}); //eslint-disable-line santa/no-module-state
    const hoverBox = mediaContainerFactory.createMediaContainer({displayName: 'HoverBox', useBackgroundDetectionMixin: false}); //eslint-disable-line santa/no-module-state
    const column = mediaContainerFactory.createMediaContainer({displayName: 'Column', useBackgroundDetectionMixin: true, isDomOnlyUpdateAllowed: false}); //eslint-disable-line santa/no-module-state
    const mediaBox = mediaContainerFactory.createMediaContainer({displayName: 'MediaBox', useBackgroundDetectionMixin: true}); //eslint-disable-line santa/no-module-state
    const stateBoxState = mediaContainerFactory.createMediaContainer({displayName: 'StateBoxState', useBackgroundDetectionMixin: true}); //eslint-disable-line santa/no-module-state
    const stateStripState = mediaContainerFactory.createMediaContainer({displayName: 'StateStripState', useBackgroundDetectionMixin: true}); //eslint-disable-line santa/no-module-state

    componentsCore.compRegistrar
        .register('wysiwyg.viewer.components.MediaBox', mediaBox)
        .register('wysiwyg.viewer.components.HoverBox', hoverBox)
        .register('wysiwyg.viewer.components.Column', column)
        .register('wysiwyg.viewer.components.StateBoxState', stateBoxState)
        .register('wysiwyg.viewer.components.StateStripState', stateStripState)
        .register('wysiwyg.viewer.components.MediaContainer', mediaContainer);

    return {
        hoverBox,
        column,
        mediaContainer,
        mediaContainerFactory
    };
});
